import React from 'react';
import classNames from 'classnames';
import BrickHelpers from 'components/bricks/helpers/brick.helpers';
import { Brick, BrickSubType } from 'components/bricks/types/brick.type';
import { useBricksPublish } from 'components/bricks/hooks/useBricksPublish';
import PublishHelpers from 'components/bricks/helpers/publish.helpers';
import TypeIcon from '../../../../type-icon';
import '../styles/publish-summary-tab.scss';
import { PublishSummaryTabStatus } from './publish-summary-tab-status';
import { OutputTabHelpers } from '../../../helpers/output-tab.helper';
import { TabState } from '../../../types/PublishTab.type';
import { PublishSummaryTabHelpers } from '../helpers/publish-summary-tab.helpers';

interface Props {
    brickIds: string[];
    subType: BrickSubType;
    title: string;
    subTitle: string;
    active?: boolean;
    tabState?: TabState;
}

/**
 * The PublishSummaryTab component is a tab that displays the status of the publish jobs of a group of bricks.
 * If there are multiple bricks in the group, the tab will display the number of bricks in the group.
 * If there are multiple bricks in the group, with multiple publish jobs, the tab will show a status based on the status of those publish jobs.
 * If there are different publish statuses for the bricks in the group, the tab will show the info status
 */
const PublishSummaryTab = ({ brickIds, subType, subTitle, title, active, tabState }: Props) => {
    const { publishId, selectedBrick } = tabState || {};

    // based on the filter that is selected for this tab we get the bricks that are selected
    const selectedBrickIds = (() => {
        if (!selectedBrick) return brickIds;
        if (selectedBrick === 'all') return brickIds;

        return [selectedBrick];
    })();

    // based on the selected bricks and selected publish job we are getting the publish jobs
    // this will be used to get the status of the publish jobs
    // and the count of the products, publish errors or validations errors
    const { publishJobs } = useBricksPublish(selectedBrickIds, publishId);

    const validationErrors = (() => {
        const bricks = selectedBrickIds.map((id) => BrickHelpers.getBrickById(id)).filter((brick) => brick) as Brick[];

        return OutputTabHelpers.getValidationErrors(bricks);
    })();

    const status = PublishHelpers.getPublishStatus(publishJobs, validationErrors);

    // get the count of the products, publish errors or validations errors based on the status
    const count = PublishSummaryTabHelpers.getTabInfoCount(status, validationErrors, publishJobs);

    return (
        <div
            className={classNames('publish-summary-tab', {
                'publish-summary-tab--active': active
            })}>
            <div className={'publish-summary-tab__info'}>
                <div
                    className={classNames('publish-summary-tab__info__icon', {
                        'publish-summary-tab__info__icon--active': active
                    })}>
                    <TypeIcon subType={subType} noBackground width={24} height={24} />
                </div>
                <div>
                    <div className="publish-summary-tab__info__title">{title}</div>
                    <div className="publish-summary-tab__info__subtitle">{subTitle}</div>
                </div>
            </div>
            <PublishSummaryTabStatus status={status} active={!!active} count={count} />
        </div>
    );
};

export { PublishSummaryTab };

import React, { useMemo } from 'react';
import { SelectChangeEvent } from '@mui/material';
import moment from 'moment';
import MenuItem from 'components/ui-components-v2/MenuItem';
import Select from 'components/ui-components-v2/Select';
import { BrickPublishJob } from 'components/bricks/types/brick-publish.type';

import '../styles/publishjob-select.scss';
import Translation from 'components/data/Translation';

interface Props {
    onSelectPublishId: (publishId: string) => void;
    selectedPublishId?: string;
    publishJobs: BrickPublishJob[];
}

// The PublishTabFilterPublishJobSelect component is responsible for rendering a select input
// that allows the user to filter publish jobs by their id.
const PublishTabFilterPublishJobSelect = ({ selectedPublishId, onSelectPublishId, publishJobs }: Props) => {
    // Handle the change event of the select input
    const onChange = (event: SelectChangeEvent<unknown>) => {
        onSelectPublishId(event.target.value as string);
    };

    // Generate the select options using useMemo for performance optimization
    const items = useMemo(() => {
        // Add an option to show the current publish
        const items = [
            {
                key: 'current-publish',
                label: Translation.get('publishTab.filters.currentExport', 'bricks')
            }
        ];

        // If there are publish jobs, add an option to show the last publish
        if (publishJobs.length) {
            items.push({
                key: 'last-publish',
                label: Translation.get('publishTab.filters.lastExport', 'bricks')
            });
        }

        // Sort the publish jobs by creation date in descending order
        const sortedPublishJobs = [...publishJobs].sort((a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime());

        // Remove the first publish job (the current one)
        sortedPublishJobs.shift();

        // Add an option for each remaining publish job
        items.push(
            ...sortedPublishJobs.map((publishJob) => ({
                key: publishJob.publishId,
                label: moment(publishJob.createdAt).format('DD-MM-YYYY HH:mm')
            }))
        );

        return items;
    }, [publishJobs]);

    // If no publish job is selected, default to 'current-publish'
    const publishId = selectedPublishId || 'current-publish';

    return (
        <Select className="publish-tab-filter-publishjob-select" value={publishId} onChange={onChange}>
            {items.map((item) => (
                <MenuItem key={item.key} value={item.key}>
                    {item.label}
                </MenuItem>
            ))}
        </Select>
    );
};

export { PublishTabFilterPublishJobSelect };

import React, { useState, useMemo } from 'react';
import { BrickPublishJob } from 'components/bricks/types/brick-publish.type';
import useBrick from 'components/bricks/hooks/useBrick';
import BrickHelpers from 'components/bricks/helpers/brick.helpers';
import { Brick, BrickOutputAction, BrickSetup } from 'components/bricks/types/brick.type';

import Card from 'components/ui-components-v2/Card';
import TabWrapper from '../../tab-wrapper';
import { OutputTabHelpers } from '../helpers/output-tab.helper';
import { TabState } from '../types/PublishTab.type';

import { PublishSummaryTabs } from './tabs';
import { PublishSummaryTabHelpers } from './tabs/helpers/publish-summary-tab.helpers';
import OutputContent from './output-content';
import '../styles/main.scss';

/**
 * The OutputTab component is responsible for rendering the Publish/Download tab in the Bricks slide panel.
 *
 * It handles the rendering of the results of a publish job, as well as the ability to start a new publish job or download.
 * It also shows the validation errors that are preventing a publish job from starting.
 *
 * The output tab combines all the components to make the publish/download tab functional.
 */
const OutputTab = () => {
    const [activeTab, setActiveTab] = useState<number>(0);
    const [tabStates, setTabStates] = useState<TabState[]>([]);

    const { brick } = useBrick();

    const outputAction: BrickOutputAction | undefined = useMemo(() => {
        const setup: BrickSetup = BrickHelpers.getBrickData(brick?.subType, 'setup');
        return setup.outputAction;
    }, []);

    // Based on the active brick we need to build up the tabs based on the children and their children
    // for example: meta campaign, meta adsets, meta ad
    const tabs = useMemo(() => {
        if (!brick) return [];

        return PublishSummaryTabHelpers.getTabs(brick);
    }, [brick]);

    if (!brick) return;

    // based on the activeTab we need to get the bricks that are part of the tab
    // if the activeTab is 0 we just return the active brick because that is always one brick
    // otherwise we need to get the bricks based on the ids of the tab
    const foundBricks = (() => {
        if (activeTab === 0) return [brick];

        const tab = tabs[activeTab];

        return tab.ids.map((id) => BrickHelpers.getBrickById(id)).filter((brick) => brick) as Brick[];
    })();

    if (!foundBricks) return;

    const selectedBrick = (() => {
        if (outputAction === 'download' && !tabStates[activeTab]?.selectedBrick) return foundBricks[0].id;
        return tabStates[activeTab]?.selectedBrick || 'all';
    })();

    // based on the filter that we have set we need to filter the bricks that is found in the active tab
    const currentBricks = foundBricks.filter((brick) => {
        if (selectedBrick === 'all') return true;
        if (selectedBrick && brick.id === selectedBrick) return true;
        return false;
    });

    // Based on the current bricks we get all their validation errors that is stored in redux
    // we use the validation errors to show different states of the publish tab and the validation errors
    const validationErrors = OutputTabHelpers.getValidationErrors(currentBricks);

    /**
     * set the selected brick in the tab state
     * @param value the selected brick
     */
    const setSelectedBrick = (value: string) => {
        const tabState = tabStates[activeTab];

        if (!tabState) {
            let publishId = 'last-publish';

            const publishJobs = foundBricks
                .map((brick) => brick.publish?.default)
                .filter((publishJob) => publishJob)
                .flat() as BrickPublishJob[];

            if (!publishJobs.length) {
                publishId = 'current-publish';
            }

            tabStates[activeTab] = { publishId, selectedBrick: value };
        } else {
            tabState.selectedBrick = value;
        }

        setTabStates([...tabStates]);
    };

    return (
        <TabWrapper>
            <div className="bricks-output-tab">
                <Card>
                    <div className="bricks-output-tab__content">
                        <PublishSummaryTabs tabs={tabs} setActiveTab={setActiveTab} activeTab={activeTab} tabStates={tabStates} />
                        <OutputContent
                            activeTab={activeTab}
                            currentBricks={currentBricks}
                            foundBricks={foundBricks}
                            selectedBrick={selectedBrick}
                            setSelectedBrick={setSelectedBrick}
                            setTabStates={setTabStates}
                            tabStates={tabStates}
                            validationErrors={validationErrors}
                        />
                    </div>
                </Card>
            </div>
        </TabWrapper>
    );
};

export default OutputTab;

import React, { useMemo, useState } from 'react';
import Translation from 'components/data/Translation';
import Typography from 'components/ui-components-v2/Typography';
import Button from 'components/ui-components-v2/Button';
import { Brick } from 'components/bricks/types/brick.type';
import PublishDetailLogs from 'components/editor-blocks/Publish/components/publish-detail-logs';
import User from 'components/data/User';
import { BrickPublishJobData } from 'components/bricks/hooks/useBricksPublish';
import SplitButton from 'components/ui-components-v2/SplitButton';
import MenuItem from 'components/ui-components-v2/MenuItem';
import PublishService from 'components/bricks/services/publish.service';
import ComponentStore from 'components/data/ComponentStore';
import LinearProgress from 'components/ui-components-v2/LinearProgress';
import { BricksComponentStore } from 'components/bricks/types/bricksComponentStore.type';
import LWFiles from 'components/data/Files';
import SnackbarUtils from 'components/ui-base/SnackbarUtils';
import { OutputTabHelpers } from '../../../helpers/output-tab.helper';
import StatusIcon from '../../status-icon';
import { BricksPublishTabStatusHelpers } from '../helpers/status.helper';
import '../styles/publishing-progress-status.scss';

interface SplitButtonOption {
    key: string;
    title: string;
    onClick: () => void;
    showLoader?: boolean;
}

interface Props {
    currentBricks: Brick[];
    status: 'success' | 'publishing' | 'error' | 'info' | 'canceled';
    publishJobs: BrickPublishJobData[];
    selectedPublishId?: string;
    download: BricksComponentStore['download'];
    setPublishDialogOpen: (open: boolean) => void;
}

const PublishingProgress = ({ currentBricks, status, publishJobs, selectedPublishId = 'last-publish', download, setPublishDialogOpen }: Props) => {
    const [downloading, setDownloading] = useState(false);

    const progress = status === 'publishing' ? BricksPublishTabStatusHelpers.calculateProgress(publishJobs) : undefined;
    const outputAction = publishJobs[0].outputAction ? publishJobs[0].outputAction : 'publish';

    const options = useMemo(() => {
        const value: SplitButtonOption[] = [];

        if (status !== 'publishing')
            value.push({
                key: 'retry',
                title: Translation.get(`publishTab.${status}-status.${outputAction}.retry`, 'bricks'),
                onClick: () => {
                    if (currentBricks.length === 0) return;
                    setPublishDialogOpen(true);
                }
            });
        else
            value.push({
                key: 'cancel',
                title: Translation.get('output.cancel.copy', 'bricks'),
                onClick: () => {
                    handlePublishJobCancel();
                }
            });

        // Check if there is anything to download
        const hasDownloadItems = publishJobs.some((publishJob) => {
            if (!publishJob.products) return false;
            return Object.values(publishJob.products).some((product) => {
                if (OutputTabHelpers.getProductType(product).action === 'download') return true;
            });
        });

        // If there are items for donload make download button primary one
        if (hasDownloadItems)
            value.unshift({
                key: 'downloadall',
                title: Translation.get('output.download.outputAll', 'bricks'),
                showLoader: downloading,
                onClick: () => {
                    handleDownloadProductsForBricks();
                }
            });

        return value;
    }, [publishJobs, download, downloading]);

    const handleDownloadProductsForBricks = async () => {
        const publishId = selectedPublishId === 'last-publish' ? undefined : publishJobs[0].publishId;
        setDownloading(true);
        const url = await PublishService.downloadBrickProducts(
            currentBricks.map((brick) => brick.id),
            publishId
        );

        if (!url) {
            SnackbarUtils.error('Failed downloading files');
            return;
        }

        const name = url.split('/').pop();
        if (!name) return;

        LWFiles.downloadFileFromUrl(url, name, () => {
            setDownloading(false);
        });
    };

    const mainButton = options.shift();
    const mainButtonColor = status === 'success' ? 'success' : 'primary';

    const handlePublishJobCancel = async () => {
        const publishId = publishJobs[0].publishId;

        const result = await PublishService.cancelPublishJob(publishId);

        if (!result) return;

        ComponentStore.removeItem('Bricks', `download.${publishId}`);
    };

    const publishDetailLogsStatus = status === 'publishing' ? 'processing' : 'finished';

    return (
        <div className={`bricks-publish-tab__status`}>
            <div className={`bricks-publish-tab__status__container`}>
                <div className="bricks-publish-tab__status__container__info">
                    <StatusIcon status={status === 'info' || status === 'canceled' ? 'partialerror' : status} />
                    <div className={`bricks-publish-tab__status__container__info__title-container`}>
                        <Typography variant="h5" color="textPrimary">
                            {Translation.get(`publishTab.${status}-status.${outputAction}.title`, 'bricks')}
                        </Typography>
                        <Typography variant="caption" color="textSecondary">
                            {Translation.get(`publishTab.${status}-status.${outputAction}.description`, 'bricks')}
                        </Typography>
                    </div>
                </div>
                <div className={`bricks-publish-tab__status__action`}>
                    {User.hasType('superadmin') && (
                        <PublishDetailLogs
                            jobId={publishJobs[0].jobToken}
                            status={publishDetailLogsStatus}
                            className={`bricks-publish-tab__status__action__logs`}
                            version={3}
                            customParam={{ custom: JSON.stringify({ brickId: currentBricks[0].id }) }}
                        />
                    )}
                    {options.length > 1 && mainButton ? (
                        <SplitButton
                            buttonGroupProps={{ color: mainButtonColor }}
                            mainButton={
                                <Button onClick={mainButton.onClick} loading={mainButton.showLoader}>
                                    {mainButton.title}
                                </Button>
                            }
                            menuItems={options.map((menuItem) => {
                                return (
                                    <MenuItem key={menuItem.key} onClick={menuItem.onClick}>
                                        {menuItem.title}
                                    </MenuItem>
                                );
                            })}
                        />
                    ) : (
                        <Button
                            onClick={mainButton?.onClick}
                            variant="contained"
                            size="small"
                            color={mainButtonColor}
                            loading={mainButton?.showLoader}
                            disabled={mainButton?.key === 'download' && !download?.[selectedPublishId]}>
                            {mainButton?.title}
                        </Button>
                    )}
                </div>
            </div>
            {!!progress && <LinearProgress className="bricks-publish-tab__status__progress" variant="determinate" value={progress} />}
        </div>
    );
};

export default PublishingProgress;

import React from 'react';
import classNames from 'classnames';
import { Brick } from 'components/bricks/types/brick.type';
import { PublishSectionDetailsRow } from './publish-details-row';
import { PublishResult } from '../types/PublishTab.type';

import '../styles/publish-section-details.scss';

interface Props {
    items?: PublishResult[];
    severity?: string;
    hasMultipleBricks?: boolean;
    classes?: {
        root?: string;
    };
    onActionClick?: (item: PublishResult, brick?: Brick) => void;
}

const PublishSectionDetails = ({ items, severity, hasMultipleBricks, classes, onActionClick }: Props) => {
    if (!items || !items.length) return;

    return (
        <div
            className={classNames('publish-section__details', classes?.root, {
                'publish-section__details--error': severity === 'error',
                'publish-section__details--warning': severity === 'warning',
                'publish-section__details--success': severity === 'success'
            })}>
            {items &&
                items.map((item: PublishResult, index) => {
                    return <PublishSectionDetailsRow key={index} item={item} hasMultipleBricks={hasMultipleBricks} onActionClick={onActionClick} />;
                })}
        </div>
    );
};

export { PublishSectionDetails };

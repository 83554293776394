import React from 'react';
import { BrickPublishJob } from 'components/bricks/types/brick-publish.type';
import { Brick } from 'components/bricks/types/brick.type';
import { PublishTabFilterBrickSelect } from './components/brick-select';
import { PublishTabFilterPublishJobSelect } from './components/publishjob-select';
import './styles/index.scss';

interface Props {
    currentBricks: Brick[];
    selectedBrick?: string;
    setSelectedBrick: (brickId: string) => void;
    selectedPublishId?: string;
    setSelectedPublishId: (value: string) => void;
}

// The PublishTabFilters component is responsible for rendering the filters in the Publish Tab.
// It receives the current bricks, the selected brick and publish job, and the functions to set them as props.
const PublishTabFilters = ({ currentBricks, selectedBrick, setSelectedBrick, selectedPublishId, setSelectedPublishId }: Props) => {
    // Extract the publish jobs from the current bricks
    const publishJobs = currentBricks.flatMap((brick) => brick.publish?.default || []).filter(Boolean) as BrickPublishJob[];

    if (publishJobs.length === 0 && currentBricks.length <= 1) return null;

    return (
        <div className="publish-tab-filters">
            {publishJobs.length > 0 && (
                <PublishTabFilterPublishJobSelect onSelectPublishId={setSelectedPublishId} selectedPublishId={selectedPublishId} publishJobs={publishJobs} />
            )}
            {currentBricks.length > 1 && <PublishTabFilterBrickSelect onSelectBrick={setSelectedBrick} selectedBrick={selectedBrick} bricks={currentBricks} />}
        </div>
    );
};

export { PublishTabFilters };

import React from 'react';
import Icon from 'components/ui-components-v2/Icon';
import Button from 'components/ui-components-v2/Button';
import useBrick from 'components/bricks/hooks/useBrick';
import { Brick } from 'components/bricks/types/brick.type';
import { PublishResult } from '../types/PublishTab.type';

import '../styles/publish-section-details-row.scss';

interface Props {
    item: PublishResult;
    hasMultipleBricks?: boolean;
    onActionClick?: (item: PublishResult, brick?: Brick) => void;
}

const PublishSectionDetailsRow = ({ item, hasMultipleBricks, onActionClick }: Props) => {
    const { brick } = useBrick(item.brickId);

    return (
        <div className="publish-section__details__row">
            <div className="publish-section__details__row__icon">
                <Icon color="disabled">{item.icon}</Icon>
            </div>

            {!hasMultipleBricks && <span className="publish-section__details__row__title">{item.title}</span>}
            {hasMultipleBricks && (
                <div className="publish-section__details__row__title-container">
                    <span className="publish-section__details__row__title">{item.title}</span>
                    <span className="publish-section__details__row__brick-title">{brick?.title}</span>
                </div>
            )}
            {item.value && item.action === 'other' && <span className="publish-section__details__row__value">{item.value}</span>}
            {item.buttonName && (
                <Button variant="text" className="publish-section__details__row__button" onClick={onActionClick && (() => onActionClick(item, brick))}>
                    {item.buttonName}
                </Button>
            )}
        </div>
    );
};

export { PublishSectionDetailsRow };

import React from 'react';
import { BrickFeedData } from 'components/bricks/types/brick.type';
import { MODEL_DATASET_DETAILS } from 'components/bricks/constants';
import AddFeedDialog from 'components/feed-management/AddFeedDialog';
import BricksComponentStoreHelper from 'components/bricks/helpers/bricks-component-store.helper';
import BrickHelpers from 'components/bricks/helpers/brick.helpers';

interface Props {
    brickId: string;
    onClose: () => void;
}

const BrickFeedDialog: React.FC<Props> = ({ brickId, onClose }) => {
    /**
     * Selects feed
     * @param feedData
     */
    const handleSelectFeed = (feedData: BrickFeedData) => {
        const brick = BrickHelpers.getBrickById(brickId);
        if (!brick) return;

        BricksComponentStoreHelper.setBrickModel(brickId, MODEL_DATASET_DETAILS, feedData); // Save the datasetId and the feedId
    };

    return <AddFeedDialog onSelectFeed={(feedData: BrickFeedData) => handleSelectFeed(feedData)} onClose={() => onClose()} />;
};

export default BrickFeedDialog;
